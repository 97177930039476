import ReactRailsUJS from "react_ujs";
import { setReactConstructor } from "../helpers/ViteHelper";
import baseFrontModules from "../helpers/entrypoint_modules/baseFront";

/*
 * This is the entrypoint for all global React components
 * modules should be added to the baseFront file to be included in this entrypoint
 */

setReactConstructor(ReactRailsUJS, baseFrontModules);
